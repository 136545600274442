   .our-core-card-content{
    padding-right: 50px;
   }
   .our-core-card-one{
     width: 100%;
     background-color: #FAFAFA;
     margin-bottom: 30px;
     padding: 10px 20px;
     height: 320px !important;
     margin-top: 0px;
   }
   .our-core-card-two{
    width: 100%;
    background-color: #F2F2F2;
    margin-bottom: 30px;
    padding: 10px 20px;
    height: 320px !important;
    margin-top: 40px;
  }
  .our-core-card-third{
    width: 100%;
    background-color: #FAFAFA;
    margin-bottom: 30px;
    padding: 10px 20px;
    height: 320px !important;
    margin-top: 80px;
  }
  .our-core-card-four{
    width: 100%;
    background-color: #F2F2F2;
    margin-bottom: 30px;
    padding: 10px 20px;
    height: 320px !important;
    margin-top: 120px;
  }


   .our-core-card-content h1 {
    font-size: 60px;
    font-family: Inter, 'Arial Narrow Bold', sans-serif !important;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 0px;
    line-height: 72.61px;
  }

   .our-core-card-content h2 {
     font-size: clamp(1.125rem, 0.9126rem + 1.1327vi, 32px);
     font-family: sora;
     font-weight: 500;
     color: #131313;
     margin-bottom: 22px;
     line-height: 38px;
   }
   
   .our-core-card-content h3 {
     font-size: 16px;
     font-family: Inter, 'Arial Narrow Bold', sans-serif;
     font-weight: 400;
     color: #333333;
     opacity: 75%;
     text-align: justify;
     margin-bottom: 24px;
     line-height: 23px;
   }
   
   @media screen and (max-width:990px) {
    .our-core-card-two , .our-core-card-one{
      height: 340px !important;
    }
    .our-core-card-third , .our-core-card-four{
      height: 340px !important;
      margin-top: 0px !important;
    }
     .our-core-col{
      margin-top: 0px !important;
     }
   }
   @media screen and (max-width:768px) {
    .our-core-card-two , .our-core-card-one{
      height: 290px !important;
      margin-top: 0px !important;
    }
    .our-core-card-third , .our-core-card-four{
      height: 290px !important;
      margin-top: 0px !important;
    }
   }

   @media screen and (max-width:400px) {
    .our-core-card-two , .our-core-card-one{
      height: auto !important;
      margin-top: 0px !important;
    }
    .our-core-card-third , .our-core-card-four{
      height: auto !important;
      margin-top: 0px !important;
    }
   }
   
   