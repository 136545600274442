
.testimonial{
    display: flex;
}
.testimonial-card{
    box-shadow: 1px 2px 9px 0px #e0e0e070;
    padding: 30px;
}
.testimonial-card p{
    color: #606060;
    font-family: Inter;
    font-size:20px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
    padding-right: 14%;
}
.testimonials-avater{
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.testimonials-avater img{
    width: 90px;
    height: 90px;
}
.testimonials-avater p{
    color: #000000;
    font-weight: 700;
    font-family: Inter;
    padding-left: 20px;
}
