.career-position{
    margin: 3rem 0;
}
.career-position h2{
    font-size: min(max(20px, calc(1.25rem + (38 - 20) * ((100vw - 320px) / (1400 - 320)))), 38px);
    color: #131313;
    font-weight: 800;
    margin-bottom: 20px;
    font-family: sora;
}
.positions{
    background: #F8F8F8;
    padding: 20px;
}
.no-positions{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
}
.no-positions p{
    padding-top: 20px;
    text-align: center;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
    color: #787878;
}

.openings{
    background: #fff;
    padding: 20px;
}