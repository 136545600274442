.realworld h1 {
    color: var(--primary-13, #131313);
font-family: sora;
/* font-size: calc(14px + (28 - 14) * ((100vw - 300px) / (1600 - 300))); */
font-size: min(max(14px, calc(1.625rem + (28 - 14) * ((100vw - 320px) / (1400 - 320)))), 28px);
font-style: normal;
font-weight: 500;
}

.realworld p{
    color: #666;
font-family: Inter;
/* font-size: calc(14px + (14 - 14) * ((100vw - 300px) / (1600 - 300))); */
font-size: min(max(14px, calc(1.625rem + (14 - 14) * ((100vw - 320px) / (1400 - 320)))), 14px);
font-style: normal;
font-weight: 500;
}

.realworld a {
    color: var(--p1, #3A65FF) !important;
font-family: Inter;
/* font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300))); */
font-size: min(max(14px, calc(1.625rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
font-style: normal;
font-weight: 600;
display: none;
align-items: center !important;
cursor: pointer;
text-decoration: none !important;
}
.realworld .icon {
    margin-left: 10px;
}
.realworld .realworld_img_style {
    width: 299px;
    height: 232px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
}

.realworld_card{
    height: 432px;
    border-radius: 20px !important;
    border: 2px solid #EFEFEF !important;
    box-shadow: 6px 8px 9px 0px rgba(224, 224, 224, 0.12) !important;
    cursor: pointer;
    transition: 0.2s all;
    margin-bottom: 30px;
}

.realworld_card:hover  {
    background: #F9F9FA;
}
.realworld_card:hover  .realworld_hover_active {
display: block !important;
}
.realworld_box {
    margin-top: 4pc;
}
@media screen and (max-width:1100px) {
    .realworld .realworld_img_style {
        width: 252px;
        height: 203px;
}
.realworld_card {
    height: 412px;
}
}


@media screen and (max-width:600px) {
    .realworld_card {
        height: 300px;
    } 
    .realworld .realworld_img_style {
        width: 190px;
        height: 140px;
}

}
@media screen and (max-width:400px) {
    .realworld_card {
        height: 310px;
    } 

    .realworld h1 {
        font-size: 18px !important;
    }

}


@media screen and (max-width:370px) {
    .realworld .realworld_img_style {
        width: 168px;
        height: 127px;
    
}
}


.new_real_world_slides{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.new_real_world_slide{
    height: 573px;
    background-color: #F6F6F6;
    width: 30%;
    transition: width 0.3s cubic-bezier(0.25, 1.25, 0.5, 1.25);;
    border-radius: 30px;
    overflow: hidden;
}

.new_real_world_slide.expand{

    width: 70%;

}

.new_real_world_slide_content{
    display: flex;align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.new_real_world_slide_content_main{
    height: 100%;
    flex-basis: 100%;
    padding: 28px;
    position: relative;
    
   
}
.new_real_world_slide_content_main_text h2{

    font-size: clamp(1rem, 0.7368rem + 1.4035vi, 2rem);
    font-family: sora;
    font-weight: 700;
    color: #131313;
    margin: 0px;

}

.new_real_world_slide_content_main_text p{
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    font-family: "inter", sans-serif;
    font-weight: 400;
    color: #666666;
    margin: 0px;
    line-height: 30px;
    margin: 0px;
    margin-top: 26px;
    visibility: hidden;
    padding-right: 50px;
}


.new_real_world_slide_content_image{
    height: 100%;
    flex-basis: 0%;
    transition: background-color 0.10s ease-in-out;
    position: relative;
  
}
.new_real_world_slide_content_image img{
    position: absolute;
    bottom: 0px;
    left: -50px;
    display: none;
}
.new_real_world_slide.expand .new_real_world_slide_content_image img{
    display: block;
}
.new_real_world_slide.expand .new_real_world_slide_content_image{
    background-color: #CAD1EA;
}


.new_real_world_slide.expand  .new_real_world_slide_content_main{

    flex-basis: 66%;

}

.new_real_world_slide.expand .new_real_world_slide_content_image{
    flex-basis: 34%;
}
.new_real_footer{
    display: flex;
    gap: 14px;
    position: absolute;
    width: 100%;
    bottom: 22px;
    
}

.new_real_footer a{
    all: unset;
    width: 190px;
    height: 41px;
    border: 1px solid #3A65FF;
    background: #3A65FF;
    color: #fff;
    border-radius: 5px;
    font-size: clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    font-family: "inter", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
}
.new_real_footer a:hover{
    background: #3156da;
}

.new_real_footer_icon{
    display: none;
}

.new_real_world_slide.expand .new_real_footer_icon{
  display: block;
  font-size: clamp(1.875rem, 1.6447rem + 1.2281vi, 2.75rem);
}

.new_real_world_slide.expand  .new_real_world_slide_content_main_text p{
    visibility: visible;
    animation: fad-in 0.40s  ease-in-out;
}

.new_real_world_case_row_one  .new_real_world_slide:first-child .new_real_world_slide_content_image img {
left: 0px !important;
}

.new_real_world_case_row_two  .new_real_world_slide:nth-child(2) .new_real_world_slide_content_image img {

    left: 0px !important;
    bottom: 40px;
}

.new_real_world_case_row_two {
    margin-top: 20px;
}

.new_real_world_slide.expand  .new_real_world_slide_content_main_text h2{
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
}

@keyframes fad-in{

    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }



}


@media screen and (min-width: 992px) and (max-width:1056px) {
    .new_real_world_slide .new_real_world_slide_content_image img {
        height: unset !important;

        top: 45px !important;
    }
}


@media screen and (max-width:1056px) {

    .new_real_world_slides{
        display: block;
        /* height: 465px;
        margin-top: 30px; */
    }
    .new_real_world_slide.expand , .new_real_world_slide{
        width: 100% !important;
        margin-top: 30px;
    }
    .new_real_world_slide_content_main_text p{
    visibility: visible !important;
    animation: unset !important;
    }
   
    .new_real_world_slide .new_real_world_slide_content_image {
        background-color: #CAD1EA;
    }
    .new_real_world_slide_content_image img{
        display: block;
    }
    .new_real_world_slide .new_real_world_slide_content_main {
        flex-basis: 66%;
    }
    .new_real_world_slide .new_real_world_slide_content_image {
        flex-basis: 34%;
    }
    .new_real_world_slide .new_real_footer_icon {
        display: block;
        font-size: clamp(1.875rem, 1.6447rem + 1.2281vi, 2.75rem);
    }

    .new_real_world_slide .new_real_world_slide_content_image img {
        height: 350px;
        width: 100%;
    }
    .new_real_world_slide {
        height: 460px;
    }
    .new_real_world_slide_content_image img {
        left: 0px;
    }
}

@media screen and (max-width:992px) {
    .new_real_footer a {
        height: 36px;
        width: 150px;
    }
    
}

@media screen and (max-width: 540px) {
    .new_real_world_slide_content{
        display: block;
    }
    .new_real_world_slide_content_main_text p {
        padding-right: 0px;
        text-align: justify;
        line-height: 28px;
    }
    .new_real_world_slide {
        height: 375px;
        border-radius: 10px;
    }
    .new_real_world_slide .new_real_world_slide_content_image{
        display: none ;
    }
    
}