
.shadow_card{
    height: 100%;
    width: 100%;
    border: 1px solid #EBEBEB;
    padding: 32px;
    box-shadow: inset 25px 0px 50px -8px #0000000a;
}

.shadow_card_row{
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.shadow_col:nth-child(1) .shadow_card{
    box-shadow: none !important;
}
.shadow_card h3{
    font-size: clamp(1rem, 0.8684rem + 0.7018vi, 1.5rem);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #121212;
    margin: 0;
    width: 90%;
    line-height: 30px;
    
}

.shadow_card p{
    font-size: clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #333333;
    margin: 0;
    margin-top: 26px;
    padding-right: 30px;
}




@media screen and (max-width:992px) {

   
    .shadow_card {
        box-shadow: inset 0px 25px 50px -8px #0000000a;
    }
   
    .shadow_card_row{
        display: block;
       
    }
    .shadow_card p{
        padding-right: 0px;
        text-align: justify;
    }

    
}

@media screen and (max-width: 540px) {

    .shadow_col{
        padding: 0px 16px !important;
    }
    
}