.what_do img, .what_do.expanded img{
    height: 100%;
    width: 100%;
    object-fit: cover;

}

.img_cards{
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
}
.what_do{
    flex-basis: 30%;
    height: 580px;
    transition: 0.20s all ease-in-out;
    position: relative;
   
}
.what_do .content{
    position: absolute;
    top: 0;
    left: 0;
    padding: 40px;
}
.what_do .content h1{
 font-size: clamp(1.125rem, 0.8947rem + 1.2281vi, 2rem);
 font-family: sora,sans-serif;
    font-weight: 600;
    color: #fff;
}

.what_do .content p{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: #fff;
     line-height: 27px;
     margin-top: 30px;
     opacity: 0;
     transition: 0.25s all ease-in-out;
    
   }  
   .what_do.expanded  .content p{
    opacity: 90%;
   
   }
.what_do.expanded{
 flex-basis: 50%;
 height: 580px;
}

.what_images img{
    height: 100%;
    width: 100%;
    object-fit: cover;

}

@media screen and (max-width : 540px) {

    .img_cards{
        display: block !important;
       
        border-radius: 5px !important;
    }
    .what_do{
        height: 500px !important;
        margin-bottom: 24px;

    }
    .what_do:last-child{
        margin-bottom: 0px !important;
    }
    .what_do img{
        border-radius: 5px;
    }
    .what_do .content{
        padding: 20px;
    }
    .what_do  .content p{
        opacity: 90%;
        display: block;
       }
   
}

@media screen and (max-width : 800px) {

    .what_do.expanded , .what_do{
        
        height: 500px;
       }
       .what_do .content{
        padding: 26px;
       }
       .what_do .content p{
        font-size: 15px;
        line-height: 28px;
       
       }
    
}

