
.solution_content_home{
    height: 100%;
    width: 100%;
    background-color: #131313;
    padding: 28px;
    padding-right: 0px;
    height: 462px;
}

.solution_content_home p{
    padding: 18px;
    font-family:  sora;
    font-size: clamp(1.125rem, 0.9605rem + 0.8772vi, 1.75rem);
    font-weight: 700;
    color: #9D9D9D;
    margin-top: 10px;
    position: relative;
    cursor: pointer;
}

.solution_content_home p.panel_active::after{
    content: '';
    position: absolute;
    width: 0%;
    height: 3px;
    background: #3A65FF;
    left: 0;
    bottom: -2px;
    animation: newfade 9s;
    } 
    .solution_content_home p.panel_active{
        color: #fff;
    }

.solution_image_box {
    width: 100% !important;
    height: 100%;
    position: relative;
}

.solutions_content_box{
    position: absolute;
    top: 32px;
    left: 32px;
    height: 100%;
    width: 80%;
    /* padding: 32px; */
   
  
}
.solutions_content_box h1{
    font-size: clamp(1.125rem, 0.7632rem + 1.9298vi, 2.5rem);
    font-family:  sora;
    font-weight: 700;
    color: #ffff;
    margin: 0px;
 
}
.solutions_content_box p{
    font-size: clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    font-family:  "inter", sans-serif;
    font-weight: 400;
    color: #FFFFFFF2;
    margin-top: 12px;
    margin-bottom: 0;
  

}
.solution_slider{
    position: relative;
}

.solution_image_box_img, .solution_content{
    height: 462px;
    width: 100% !important;
    position: relative;
}

.solution_content_home p:hover{
    background: #282828;
    color: #fff;
}

.exp_btn{
   height: 41px;
    width: 190px;
    text-decoration: none;
    color: #fff;
    background-color: #3A65FF;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 26px;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

}

.exp_btn a{
    text-decoration: none;
    color: #fff;
}

.explore_btn:hover{
    background-color: #385ad2;
}


@keyframes newfade {

    100%{
        width: 100%;
    }

}

@media screen and (max-width: 992px) {
    .sliding{
        display: block;
        margin-bottom: 30px;
        padding: 0px !important;
    }
    .solution_image_box_img{

        height: 280px !important;
    }
    .cont_slid{
        padding-right: 16px !important;
        margin-bottom: 30px;
        display: none;
    }
    .solution_content_home {
        height: 400px;
    }
    .solution_content_home{
        padding: 15px !important;
    }
    .exp_btn{
        height: 36px;
        width: 150px;
    
    }
    .solutions_content_box {

        left: 0;
        top: 0;
        padding: 26px;
        width: 100%;

    }
    
}