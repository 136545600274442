.about_realworld h1 {
    color: var(--primary-13, #131313);
    font-family: sora;
    /* font-size: clamp(1.375rem, 1.0789rem + 1.5789vi, 2.5rem); */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    position: absolute;
    left: 0px;
    top: 23px;
}
.about_realworld .behind_text {
    color: #F0F0F0;
    font-family: Inter;
    /* font-size: clamp(4.6875rem, 2.9276rem + 9.386vi, 11.375rem); */
    font-size: 80px;
    font-weight: 700 !important;
    /* line-height: 182.3px; */
}
.about_realworld p {
    color: #333333;
    font-family: Inter;
    /* font-size: clamp(1.0625rem, 1.0132rem + 0.2632vi, 1.25rem); */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-wrap: balance;
    letter-spacing: -.25px;
}
.about_realworld_card {
    /* height: 540px; */
    height: 330px;
    border-radius: 5px !important;
    border: 2px solid #EFEFEF !important;
    box-shadow: 6px 8px 9px 0px rgba(224, 224, 224, 0.12) !important;
    margin-bottom: 30px;
}
.about_realworld_box {
    margin-top: 4pc;
}
@media screen and (max-width: 1400px) {
    .about_realworld .behind_text {
        font-size: 150px;
        font-weight: 600 !important;
        line-height: 182.3px;
    }
    .about_realworld p {
        padding: 0 !important;
        transition: 1s;
    }
    .about_realworld_card {
        height: 100%;
    }
}
@media screen and (max-width: 1300px) {
    .about_realworld .behind_text {
        font-size: 120px;
        font-weight: 600 !important;
    }
}
/* @media screen and (max-width: 992px) {
    .about_realworld_card {
        height: auto;
    }
    .behind_text{
        font-size: 100px !important;
        font-weight: 300 !important;
    }
    .behind_text {
        margin-left: 0 !important;
    }
} */
/* @media screen and (max-width: 768px) {
    .about_realworld_card {
        height: auto !important;
    }
}
} */
@media screen and (min-width: 1100px) and (max-width: 1380px) {
    .about_realworld_card {
        height: 545px !important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1056px) {
    .about_realworld_card {
        height: 605px !important;
    }
}

@media screen and (max-width: 992px) {

    .mt-80 {
        margin-top: 45px;
    }
    .about_realworld p {
        text-align: justify;
    }
    
}

@media screen and (max-width: 450px) {
    .about_realworld .behind_text {
        margin: 0 !important;
        font-size: 90px;
    }
}

