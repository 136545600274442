.banner-section{
    /* background-image: url('./../../assets/images/unified_img.png'); */
    /* height: 339px; */
    
    /* padding-right: 55px; */
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.page-title{
    /* font-size: min(max(20px, calc(1.25rem + (64 - 20) * ((100vw - 320px) / (1400 - 320)))), 64px); */
    color: white;
    font-weight: 700;
    /* margin-top: 40px; */

    font-family: sora;
}
.smalltext{
    color: #A5B9FF;
    font-size: min(max(15px, calc(0.9375rem + (24 - 15) * ((100vw - 320px) / (1400 - 320)))), 24px);
    font-family: 'Inter';
    font-weight: 700;
    margin-bottom: 0;

}

@media screen and (max-width:800px) {
    .banner-section {
        /* height: 175px; */
        background-size: cover !important;
        background-position:top center !important;
        /* margin-top: 60px; */
     }
     .banner_img_case{
        display: none;
     }
     .img_col_case{
        display: none;
     }
    
}
@media screen and (min-width:800px) and (max-width: 1056px) {

.banner_img_case{
    max-width: 85%;
}
/* .banner-section {
    height: 300px;
} */
}