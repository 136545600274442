.cont-div{
    background-color: #F6F6F6;
}
.cont-div h1{
    font-family: sora;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;

}
.cont-div button{
    font-family: 'Inter', sans-serif;;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    color: #FFFFFF;
    background-color: #3A65FF;
    border: none;
    width: 191px;
    
}
.count-div h1{
    font-family: sora;
    font-size: 52px;
    font-weight: 500;
    line-height: 62.4px;
    color: #000000;
}
.count-div p{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    color: #000000;
    opacity: 60%;

}