.letter_box{

    border: 1px solid #989898;
    padding: 26px;
    width: 88%;
    margin: auto;
    margin-bottom: 75px;
    margin-top: 50px;
}

.main_head {
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-weight: 700;
    color: #131313;
    font-family: sora;
    margin: 0px;
}

 .letter_box h6{
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    font-weight: 400;
    color: #000000BF;
    padding: 0px 12px;
    line-height: 32px;
    margin-top: 26px;
    text-align: justify;

}



.main_content h3{
    font-family: sora,sans-serif;
    font-size: clamp(1rem, 0.7368rem + 1.4035vi, 2rem);
    font-weight: 500;
    color: #131313;
    margin-top: 26px;
    margin-bottom: 0px;
}

.main_content p{
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    font-weight: 400;
    color: #000000BF;
    line-height: 32px;
    margin-top: 26px;
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 0px;
}
.main_content{
    padding: 0px 12px;
}
.main_content ul li{
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    font-weight: 400;
    color: #000000BF;
    line-height: 32px;
    margin-top: 26px;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px; 
}

@media screen and (max-width:991px) {

    .letter_box{
        width: 100%;
        margin: 30px auto;
    }
}