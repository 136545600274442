.about_content_main p{
    font-family: Inter;
    color: #5B5B5B;
    font-size: 18px;
    line-height: 27px;
    /* text-wrap: balance; */
    font-weight: 400;
    letter-spacing: -.25px;
    /* text-align: justify; */
    margin-bottom: 50px;
}
.about_content_main{
  padding-right: 80px;
}
.about_content_main p span{
color: #121212;
font-weight: 700;
}
.count_section_about{
  margin-top: 55px;
  margin-bottom: 30px;
}
.count_about h3{

  font-size: 32px;
  font-family: sora;
  color: #161616;
  margin: 0px;
  font-weight: 600;
  letter-spacing: -.5px;
  line-height: 42px;

}

.count_about p{
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color:  #313131BF;;
  margin: 0px;
  font-weight: 400;
  letter-spacing: -.25px;

  
}
.count_about{
  margin-bottom: 32px;
}

@media screen and (max-width: 991px) {

  .about_content_main {
    padding-right: 0px;
  }
  .about_content_main p {
    margin-bottom: 40px;
    text-align: justify;
    line-height: 31px;
    font-size: 16px;
  }
  .count_section_about{
    margin-top: 40px;
  }
  .count_about {
    text-align: center;
  }
  
}

