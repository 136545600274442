.section-bg-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0;
}


.insurance-content{
    margin-right: 54%;
}

.insurance-content h1{
    color: #fff;
    font-family: sora;
    font-size: calc(14px + (38 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    position: relative;
}

.insurance-content h1::before{
    content: '';
    width: 60px;
    height: 8px;
    background: var(--p1, #3A65FF);
    display: inline-block;
    position: absolute;
    top: -12px;
}

.insurance-content ul li{
    color: #D0C2C2;
    font-family: 'Inter', sans-serif;
    font-size: calc(14px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-right: 15%;
    padding-bottom: 20px;
}

@media screen and (max-width:1450px) {
    .section-bg-img{
        height: 900px;
    }
}


@media screen and  (max-width: 550px) {
    .insurance-content{
        width: 100% !important;
    }
    
}