.case_result_row{
    display: flex;
    justify-content: center;
}

.case_result_card{
    height: 359px;
    width: 100%;
    padding: 30px;
}

.case_result_row .case_result_card:nth-child(odd){
    background: #FAFAFA;

}

.case_result_row .case_result_card:nth-child(even){
    background: #F2F2F2;

}

.case_result_card_content h2{
    color: #3A65FF;
    font-size: clamp(2.25rem, 2.0033rem + 1.3158vi, 3.1875rem);
    font-family: 'inter', sans-serif;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom: 0;

}

.case_result_card_content h3{
    color:  #000000;
    font-size: clamp(1.125rem, 0.9605rem + 0.8772vi, 1.75rem);
    font-family: sora;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 0;
    
}
.case_result_card_content p{

    color:  #333333;
    font-size: clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    font-family: 'inter', sans-serif;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0;
    opacity: 75%;
    
}

.case_result_row .case_result_card:nth-child(2){
    margin-top: 80px;
}

.case_result_row .case_result_card:nth-child(3){
    margin-top: 160px;
}
.case_result_row .case_result_card:nth-child(4){
    margin-top: 240px;
}

.case_result_row .case_result_card:nth-child(5){
    margin-top: 320px;
    height: 100%;
}

@media screen and (max-width:992px) {

    .case_result_row{
        display: block;
    }
    .case_result_row .case_result_card{
        margin-top: 25px !important;
        height: 100% !important;
    }
    .shadow_card {
        box-shadow: inset 0px 25px 50px -8px #0000000a;
    }
    .overview_content{
        padding: 0px !important;
    }
    .mt-75.case_study{
        margin-top: 40px;
    }


    
}

@media screen and (min-width: 1000px)  and ((max-width: 1380px)) {

    .case_result_card{
        height: 100% !important;
    }

}