.related_study_img {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    overflow: hidden;

}
.casestudy_mt_100 {
    margin-top: 100px;
}
.casestudy_btn {
    background-color: #3A65FF;
padding: 11px 16px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
font-weight: 500;
border-radius: 5px !important;
position: absolute;
bottom: 0;
text-decoration: none;

}

.casestudy_btn:hover{
    background-color: #385ee7;
}

.realted_card {
    padding: 0;
    margin-bottom: 70px !important;
    box-shadow: none !important;
    border: none !important;
    height: 490px;

}
.relatedcase_bg_color {
    background: #FBFBFB;
    height: 246px;
    position: relative;
}


.realworld p {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
}
.relatedcasestudy_card {
    padding: 0;
}
.relatedcasestudy_card  h1 {
    color: var(--primary-13, #131313);
font-family: sora;
font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
font-weight: 700;
line-height: 24px; 
}
.relatedcasestudy_card  p {
    color: rgba(0, 0, 0, 0.70);
    font-family: Inter;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
}

@media screen  and (max-width:1300px) {
    .related_study_img {
        height: 169px;
        width: 290px;
        object-fit: contain;
    }
}

@media screen and (min-width:1000px) and (max-width: 1380px){
    .realted_card {
        height: 525px;
    }
    .related_study_img {
        width: 86%;
    }
}