.file_upload_job {
    width: 100%;
    height: 76px;
    border-radius: 12px;
    background-color: #fff;
    margin-top: 12px;
    text-align: left;
    padding: 15px;
    border: 2px solid #f5f5f5;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
}

.ml-2 {
    margin-left: 20px;
}

.btn-flex {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.btn-flex h6 {
    margin-bottom: 0;
    font-family: Inter;
    font-size: 17px;
    font-weight: 700;
    color: #7D7F82;
}

.btn-flex p {
    margin-bottom: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #777777;
}

.required {
    color: red;
}

.form-label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
}

.apply {
    background-color: #3A65FF;
}

.offcanvas {
    width: 50% !important;
}

.chip_design {
    padding: 5px 15px;
    background: #edf5fc;
    border-radius: 20px;
    border: 1px solid #89bdef;
    display: inline-block;
    margin-left: 10px;
}

.uploaded_file {
    margin-top: 13px;
}

.upload_container {
    display: flex;
    align-items: center;
    border: 1.6px solid #dee2e6;
    height: 100px;
    padding: 26px;
    gap: 20px;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 20px;
}

.upload_container h5 {
    margin: 0px;
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    line-height: 29px;
    color: #333333;
    font-weight: 700;
    font-family: Inter;
}

.upload_container p {
    margin-bottom: 0px;
    margin-top: 0px;
    /* font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem); */
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    font-weight: 400;
    font-family: Inter;
}

.clear_resume {
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 15px;
    width: 15px;
    background-color: #c29e9e;
    border-radius: 50%;
    position: relative;
    left: 6px;
    top: -2px;
}

@media screen and (max-width:991px) {
    .margin_form {
        margin-bottom: 18px !important;
    }

}

@media screen and (max-width:991px) {

    .btn-flex h6 {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .offcanvas {
        width: 100% !important;
    }
}