.arrow-button {
    display: flex;
    color: #C2FFE9;
    background-color: #00E692;
    padding: 10px 16px;
    border-radius: 20px;
    transition: all .3s ease;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
}

.arrow-button > .arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}

.arrow-button > .arrow::before {
    display: block;
    background-color: currentColor;
    width: 3px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 0;
    bottom: calc(-2px / 2);
    transform: rotate(45deg);
    transition: all .3s ease;
    content: "";
    right: 0;
}

.arrow-button:hover > .arrow {
    transform: rotate(-45deg) translate(4px, 4px);
    border-color: text-hover-color;
    margin-left: 10px;
}
.case_study_btn:hover {
    transition: 0.3s all;
    background-color: #0031e3 !important;
  }
.arrow-button:hover > .arrow::before {
    opacity: 1;
    width: 15px;
}

.arrow-button:hover {
    background-color: #017A4F;
    color: #fff;
}