



.service_steps_title{
    font-size: clamp(1.125rem, 0.7961rem + 1.7544vi, 2.375rem);
    font-family: sora;
    font-weight: 700;
    color: #131313;
    margin-bottom: 40px;

}

.services_steps_slides{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 490px;
    width: 100%;
    overflow-y: hidden;
  }


.services_steps_slide{

    height: 100%;
    background-color: #F6F6FA;
    flex-basis: 33.3%;
    border-radius: 50px 50px 0px 0px;
    padding: 32px;
    transition: 0.20s all ease-in-out;
    transform: translateY(55%);
}
.services_steps_slide:first-child{
    border-radius: 50px 25px 0px 0px;
}
.services_steps_slide:last-child{
    border-radius: 25px 50px 0px 0px
}
.services_steps_slide_number{
    font-size: clamp(1.875rem, 1.3816rem + 2.6316vi, 3.75rem);
    font-family: "inter", sans-serif;
    font-weight: 400;
    color: #3A65FF;
    transition: 0.20s all ease-in-out;
}

.services_steps_slide_title{
    font-size: clamp(1rem, 0.8026rem + 1.0526vi, 1.75rem);
    font-family: sora, sans-serif;
    font-weight: 700;
    color: #161616;
    margin-top: 26px;
    transition: 0.20s all ease-in-out;
}
.services_steps_slide_description{
    font-size:clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    font-family: "inter", sans-serif;
    font-weight: 400;
    color: #fff;
    margin-top: 26px;
    line-height: 27px;
    transition: 0.20s all ease-in-out;
    opacity: 0;
}

.services_steps_slide.expanded{
    background-color: #161616;
    transform: translateY(0%);
}

.services_steps_slide.expanded .services_steps_slide_number,  .services_steps_slide.expanded .services_steps_slide_title{
    color: #fff;
}
.services_steps_slide.expanded .services_steps_slide_description{
    opacity: 1;
}


@media screen and (min-width:1000px) and (max-width: 1380px) {

    .services_steps_slides{

        height: 580px;
        margin-top: 20px;
    } 
    
}



@media screen and (max-width:992px) {
    .services_steps {
        margin-top: 40px;
        margin-bottom: 0px;
    }
    .services_steps_slides{
        
        display: block;
        overflow-y: auto;
        height: unset !important;

    }
    .services_steps_slide, .services_steps_slide.expanded{
           transform: unset !important;
           height: 100%;
           border-radius: 10px !important;
           margin-bottom: 26px;
    }
    .services_steps_slide:last-child, .services_steps_slide.expanded:last-child{
        margin-bottom: 0px;
    }
    .services_steps_slide.expanded {
        background-color: #F6F6FA;
    }
    .services_steps_slide.expanded .services_steps_slide_number{
         color: #3A65FF;
    }
    
    .services_steps_slide.expanded .services_steps_slide_title {
        color: #161616;
    }
    .services_steps_slide .services_steps_slide_description {
        color: #161616;
        opacity: 1;
    }
}

@media screen and (max-width: 540px) {

    .services_steps_slide, .services_steps_slide.expanded{
    padding: 20px;
    }

}