.unified_team_carousal{
  padding: 20px 20px;
width: 100%;
background-color: #000000;
overflow: hidden;
border-bottom: 1px solid #333 !important;


}

/* 
.unified_team_carousal_container{
    display: flex;
    padding: 0px 30px;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    animation: scroll 11s  linear infinite alternate ;
    white-space: nowrap;
}
.unified_team_carousal_title{

    display: flex;
    align-items: center;
    
    
    
}
.unified_team_carousal_title p{
    color: #fff !important;
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    font-weight: 500;
} */
.unified_team_carousal_title{

  display: flex !important;
  align-items: center;
  justify-content: center;
  /* width: 200px !important; */
  
  
}

.unified_team_carousal_title p{
  color: #fff !important;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  font-weight: 500;
  margin: 0px 30px;
  white-space: nowrap;
  /* margin-left: 30px; */
}
.star{
    height: 16px;
    width: 16px;
    margin-left: 15px;
    
}
/* .slick-slide{
  width: fit-content !important;
} */

@keyframes scroll {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-100%);
    }
  }
  

  .unified_team_main_content{
   
    width: 100%;
 
    height: 100%;
  }
 
  .unified_header h2{
    font-family: sora;
  color: #000 ;
  /* font-size: calc(1.3125rem + (38 - 21) * ((100vw - 320px) / (1400 - 320))); */
  font-size: clamp(1.25rem, 0.9539rem + 1.5789vi, 2.375rem);;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 47px;

  }
  .unified_header h4{
    font-family: sora, sans-serif;
    color: #333333 ;
    /* font-size: calc(1.0625rem + (30 - 17) * ((100vw - 320px) / (1400 - 320))); */
    font-size: clamp(1.125rem, 0.9276rem + 1.0526vi, 1.875rem);
    margin-bottom: 0;
    font-weight: 400;
    line-height: 37px;
  }
  .unified_header{
    margin-top: 40px;
  }

  .unified_counter h4{
    color: #000 ;
    font-family: 'Inter', sans-serif;
    font-size: min(max(15px, calc(0.9375rem + (20 - 15) * ((100vw - 320px) / (1400 - 320)))), 20px);
    font-weight: 450;
    margin-bottom: 0;
  }

  .unified_counter h2{
    font-family: sora, sans-serif;
    color: #000 ;
    font-size: min(max(20px, calc(1.25rem + (52 - 20) * ((100vw - 320px) / (1400 - 320)))), 52px);  
  font-weight: 600;
    margin-bottom: 0;
  }
  
  
  .unified_clients_card{
    /* width: 561px; */
    width: 90%;
    height: 100%;
    background: #3a393929;
  }
  .unified_clients_card_conetnt{
    width: 100%;
    height: 100%;
  }
  .flex_items{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
   
  }

  .flex_items img:last-child{
   
      grid-column: 1 / span 3;
      
    
  }
  .count_section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px ;
  }
  .clients_section{
    width: 100%;
 
    margin-top: 62px;
    margin-bottom: 60px;
  }
 .clients_box_header h2{
  font-size: min(max(26px, calc(1.625rem + (74 - 26) * ((100vw - 320px) / (1400 - 320)))), 74px);
  color: #80808047;
  font-family: sora;
  font-weight: 600;
 }
 .clients_box_header{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateZ(270deg);
 }
 .no_of_clinets{
  margin-top: 60px;
 }
 .no_of_clinets_row{

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 20px;
 
 }
.clients_card{
  width: 180px;
  text-align: center;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%)

}
.clients_tieup {
  color: #666666;
  font-family: 'Inter';
  font-size: min(max(13px, calc(0.8125rem + (18 - 13) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 600;
}

@media screen and (max-width:1450px) {
  .no_of_clinets_row{
    grid-template-columns: 1fr 1fr 1fr ;
  }
}


  @media screen and (max-width:1024px) {
    .unified_team_carousal_title p {
      font-size: 14px;
    }
    .no_of_clinets_row{

    
      grid-template-columns: 1fr 1fr 1fr ;
    }
    .clients_section{
height: 100%;
    }
  
    
  }

  @media screen and (max-width:450px) {
    .unified_team_carousal_title p {
      font-size: 13px;
    }
    .flex_items img{
      width: 70% !important;
    }
  }


  @media screen and (max-width:540px) {
    .unified_header {
      margin-top: 30px;
  }


.unified_clients_card{
margin: auto;
}
.flex_items img:nth-child(8), .flex_items img:nth-child(6), .flex_items img:nth-child(7), .flex_items img:nth-child(9)  {
  width: 40% !important;
}
.flex_items img:last-child {
  
  margin-top: 18px;
  margin-bottom: 18px;
}
    .unified_team_carousal{
      padding: 10px 20px;
    }
    /* .flex_items img:last-child {
      width: 60%;
  } */
  .count_section {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  
  }
  .no_of_clinets_row{

    
    grid-template-columns: 1fr 1fr !important;
    row-gap: 20px;
   
   }

  
  }


  /* carouusal */

  .main_carouasl {
    display: flex !important;

  }

  img {
    display: inline-block;
  }
  
  .casrolas .unified_team_carousal_title:first-child{
    animation: bannermove 65s linear infinite;
  }



  /*keyframe animations*/
 @keyframes bannermove {
   0% {
      margin-left: 0px;
   }
   100% {
      margin-left: -6000px;
   }
   
  }

 /*Responsive*/

 @media screen and (max-width:992px){
  .mobile_paddleft{
    padding-left: 24px;
  }
    .unified_header {
      margin-top: 30px;
  }
 

.unified_clients_card{
margin: auto;
}
.flex_items img:nth-child(8), .flex_items img:nth-child(6), .flex_items img:nth-child(7), .flex_items img:nth-child(9)  {
  width: 40% !important;
}
.flex_items img:last-child {
  
  margin-top: 18px;
  margin-bottom: 18px;
}
    .unified_team_carousal{
      padding: 10px 20px;
    }
.count_section{
  justify-content: unset;
  
}
.count_section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 0;

}
.unified_counter{
  margin: 22px;
}
.no_of_clinets_row{

    
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  row-gap: 20px;
 
 }
 .clients_box_header{
  transform: unset !important;
  margin-top: 20px;
 }
 .no_of_clinets{
  margin-top: 40px;
 }
 .clients_section{
  width: 100% !important;
  height: 100% !important;
  margin-top: 20px;

 }
 .clients_card{
  width: auto;
  margin: 0px 16px;
 }
 .clients_tieup{
  margin-left: 24px;
 }
 /* .cl_card{
  padding-bottom: 32px ;
 } */
 .hero_section_inner_section{

  align-items: flex-end;
  padding-bottom: 30px;

 }
 .clients_card:nth-child(1) img,  .clients_card:nth-child(2) img,  .clients_card:nth-child(4) img, .clients_card:nth-child(9) img, .clients_card:nth-child(10) img {
  width: 60%;
}
.clients_card:nth-child(3) img, .clients_card:nth-child(5) img, .clients_card:nth-child(6) img, .clients_card:nth-child(7) img , .clients_card:nth-child(8) img{
  width: 45%;
}
  }



    @media screen and (max-width:1300px){
    .flex_items img{
      width: 80% ;
    }
    .flex_items img:nth-child(8), .flex_items img:nth-child(6), .flex_items img:nth-child(7), .flex_items img:nth-child(9)  {
      width: 45% ;
    }


  }
   @media screen and (min-width : 600px) and (max-width:820px) {

    .flex_items img{
      width: 45% ;
    }
    .flex_items img:nth-child(8), .flex_items img:nth-child(6), .flex_items img:nth-child(7), .flex_items img:nth-child(9)  {
      width: 29% !important;
    }
    
  }

  @media screen and (max-width: 768px) {
    .clients_section{
      margin-bottom: 0px;
     }

  }


 