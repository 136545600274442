.real-time-example-sec {
    margin: 3rem 0;
}

.real-time-example {
    width: 80%;
}

.real-time-example h1 {
    font-family: sora;
    font-size: min(max(20px, calc(1.25rem + (40 - 20) * ((100vw - 320px) / (1400 - 320)))), 40px);
    font-weight: 600;
    text-align: left;
    color: #131313;
}

.real-time-example p {
    color: #656565;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    padding-top: 16px;
    padding-bottom: 20px;
}

.real-time-example p b{
    color: #000;
}

.real-time-cards{
    border: .05px solid #B3B3B3;
    padding: 40px;
    position: relative;
    overflow: hidden;
    transition: background-image 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.real-time-cards:hover{
    background-repeat: no-repeat;
    background-image: var(--bg-image);
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.real-time-cards .card-content {
    position: relative;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
  }

.real-time-cards:hover .card-content {
    opacity: 1;
  }

  .real-time-cards::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: 0;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    background-size: cover;
    background-position: center;
  }

  .real-time-cards:hover::before {
    opacity: 1;
  }

.real-time-cards:hover h2{
    color: #fff;
    margin-top: 30px;
}

.real-time-cards:hover p{
    color: #fff;
}

.real-time-cards:hover .card-icon{
    display: none;
    z-index: 1;
}

.real-time-cards:hover .highlight-line{
    display: block;
}

.real-time-cards h2{
    color: #161616;
    font-family: sora;
    font-size: min(max(20px, calc(1.25rem + (32 - 20) * ((100vw - 320px) / (1400 - 320)))), 32px);
    font-weight: 700;
}

.real-time-cards p{
    color: #656565;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
}

.real-time-cards .card-icon{
    padding: 20px 0;
    z-index: 1;
}

.real-time-cards .highlight-line{
    width: 100px;
    height: 6px;
    display: none;
    background: #3A65FF;
    border-radius: 50px;
}


@media screen and (min-width: 1300px) {
    .real-time-cards{
        min-height: 335px;
    }
}
@media screen and (max-width: 991px) {
    .real-time-example {
        width: 100%;
    }
}