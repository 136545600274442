.time_line_section{
    height: 100%;
    width: 100%;
    background: #F6F6F6;
    padding: 75px 0px;
    position: relative;
}

.description_box{
    padding: 32px;
    background-color: #fff;
    height: 100%;
    width: 572px;
    border-radius: 10px;


}

.description_box p{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    line-height: 27px;
    color: #535353;
    font-family: "Inter", sans-serif;
    text-align: justify;

}
.description_box ul {
    margin-top: 28px;
}
.description_box ul li{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    line-height: 28px;
    color: #333333;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    margin-bottom: 6px;
}
.stages{
    padding: 0px 0px;
    padding-left: 100px;
}
.stages h1{
    font-family: sora;
    font-size: clamp(3.75rem, 2.2697rem + 7.8947vi, 9.375rem);
    line-height: 100px;
    font-weight: 700;
    color: #949EC33B;
    margin: 0px;
 

}
.stages h3 span{
    color: #434343 !important;
}
.stages h3{
    font-family: sora;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    line-height: 52px;
    font-weight: 600;
    color: #161616;
    margin: 0;
    margin-top: 60px;
}

.stages.even{
    text-align: end;
    padding-right: 100px;
}
.description_box.even{
    margin-left: 100px;
}

.stages.even{
    padding-left: 0px !important;
}

.time_row{
    margin-top: 140px;
}
.new_row{
    margin-top: 100px !important;
}

.first>h1{
    color: #3A65FF ;
}
.time-line_path{
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.custom_fill_line{
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #3a65ff;
    height: 0%;
    width: 8px;
    z-index: 99;
}


/*  use case */

.usecase_section{

    margin-top: 50px;
    margin-bottom: 75px;
}

.use_case_section_header{
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-family: sora;
    color: #131313;
    margin: 0px;


}

.use_case_content{
    font-family: 'Inter', sans-serif;
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    color: #656565;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 0px;
    line-height: 30px;
}

.problems .problem_stage{
    font-size: clamp(1.125rem, 0.8947rem + 1.2281vi, 2rem);
    font-family: sora;
    color: #FF0000;
    margin: 0px;
    font-weight: 700;
}

.problems .problem_name{
    font-size: clamp(1.125rem, 0.8947rem + 1.2281vi, 2rem);
    font-family: sora;
    color: #131313;
    margin: 0px;
    font-weight: 700;
    line-height: 35px;
    margin-top: 22px;
}
.problem_content{
    font-family: 'Inter', sans-serif;
    font-size:  clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    color: #535353;
    font-weight: 500;
    margin-top: 22px;
    margin-bottom: 0px;
    line-height: 23px;
}
.problems.odd{
    text-align: end;
    padding-left: 55px;
    padding-right: 75px;
}

.solution{
    width: 570px;
    background-color: #EFFFF0;
    border-radius: 10px;
    height: 100%;
}
.solutions_content p{
    font-family: 'Inter', sans-serif;
    font-size:  clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    color: #535353;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 0px;
    line-height: 23px;
}
.solutions_content_title{
    color: #17A600;
    font-family: sora;
    margin: 0px;
    font-weight: 700;
    font-size: clamp(1rem, 0.8684rem + 0.7018vi, 1.5rem);
 

}
.solutions_content{
    padding: 30px;
}
.solution.odd{
  margin-left: 75px;
}

.solution.even{
    margin-right: 75px;
   
}

.problems.even{
    padding-left: 75px;
    padding-right: 30px;
}
.usecase_timeline{
    height: 100%;
    width: 100%;
    position: relative;
}
.new_path{
    height: 100% !important;
}
.relative{
    position: relative;
}
.usecase_timeline{
    margin-top: 80px;
}



@media screen and (min-width: 1900px) and (max-width:2560px){

    .stages{
        padding-left: 80px;
    }
    .description_box {
     
        width: 590px;
        
        }
        .stages.even{
            padding-left: 0px;
        }
        .description_box.even {
            margin-left: 70px;
        }
}

@media screen and (min-width: 1100px) and (max-width:1380px){

    .description_box{

        width: 490px;
        margin: 0px;


    }
    .description_box.even {
        margin-left: 55px;
    }
    .stages{
        padding: 0px;
        padding-left: 60px;
    }
    .stages.even {
        padding-right: 100px;
    }
    .solution {
        width: auto;
    }
   
    .usecase_timeline .time_row {
        margin-top: 45px;
    }
}

@media screen and (min-width: 1000px) and (max-width:1050px){

    .description_box{

        width: 400px;
        margin: 0px;


    }
    .stages{
        padding: 0px;
        padding-left: 60px;
    }
    .stages.even {
        padding-right: 100px;
    }
    .description_box.even {
        margin-left: 50px;
    }

    .solutions_content p{

        font-size: 13px;


    }
.problems.odd {

    padding-left: 9px;
}

.solution {
    width: 88%;
}
.solution.odd {
    margin-left: 35px;
}
.usecase_timeline .time_row {
    margin-top: 28px;
}

}


@media screen and (max-width:991px) {

    .time-line_path{
    display: none;
        top: 22%;
        left: 3%;
        transform: translate(-3%, -22%);
    }
    .responsive_row_odd .col-lg-6:first-child{

        order: 2;

    }
    .time_row.responsive_row_even .col-lg-6:first-child{
        order: 0 !important;
    }
    .stages h3{
           margin-top: 18px;
           line-height: 35px;
    }
    .description_box{
        width: 100%;
        margin: 24px auto;
        height: fit-content;
        
    }
    .time_row{
        margin-top: 20px;
    }
    .stages.even{
        text-align: left;
        padding-right: 0px;
        padding-left: 15px !important;
    }
    .stages{
        padding-left: 15px;
    }
    .description_box.even{
        margin-left: auto;
    }
   .time_line_section {
    padding: 50px 0px;
   }
  .solution.odd {
margin-left: 0px;
  }
  .problems.odd , .problems.even{
   text-align: left;
   padding-left: 15px;
    padding-right: 35px;
  }
  .solution{
    width: 100%;
    margin-top: 40px;
  }
 .usecase_timeline  .responsive_row_odd  .col-lg-6:first-child {
    order: 0 !important;
}
.usecase_timeline  .responsive_row_even  .col-lg-6:first-child {
    order: 2 !important;
}
.usecase_timeline{
    margin-top: 40px;
}
.usecase_timeline .time_row {
    margin-top: 90px;
}

.use_case_content, .problem_content{
text-align: justify;

}
.problems .problem_name {
    margin-top: 8px;
    line-height: 28px;
}
.solutions_content  p{
    text-align: justify
}
}

