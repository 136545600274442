@media screen and (max-width:1400px) {

    .card_margin,
    .new_card_enter {
        margin-bottom: 24px !important;
    }

}
.mb-60 {
    margin-bottom: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.main-title {
    font-family: "sora" !important;
    /* font-size: min(max(20px, calc(1.25rem + (38 - 20) * ((100vw - 320px) / (1400 - 320)))), 38px) !important; */
    font-size: 32px;
    line-height: 42px;
}

.main-content {
    font-family: Inter, 'sans-serif';
    font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
    color: #666666;
}

.f18 {
    font-size: 18px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-100 {
    margin-top: 75px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-80 {
    margin-top: 80px;
}

.underline {
    width: 100px;
    height: 4px;
    background-color: blue;
}

.about_content {
    font-family: 'Inter';
}

.card_height_set .simple_card {
    height: 216px !important;
}

h3.main-title {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Inter';
}
.right_arrow_white {
    width: 10px;
    margin-left: 12px;
    height: 15px;
    object-fit: fill;
    display: none;

    
}
.case_study_btn {
    transition: 0.3s all;
}

.case_study_btn:hover .right_arrow_white {
    display: block;
   
   
 
}


.px-200 {
    padding-left: 200px;
    padding-right: 200px;
}

.analytics-card {
    border: 2px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
    padding: 20px;
    min-height: 330px;
}

.datacard_height_set .simple_card {
    height: 176px !important;
}

.mt-75 {
    margin-top: 75px;
}

.mb-75 {
    margin-bottom: 75px;
}

.expertise_section .card-style-1 {
    height: 84px !important;
}

.cognitive p {
    font-family: Inter, 'sans-serif';
    font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
    color: #666666;
    margin-top: 20px;
}

.slick-dots {
    position: absolute;
    bottom: -40px;
}
.solution_card .slick-dots {
    position: absolute;
    bottom: -30px;
}
.slick-dots li.slick-active button:before {
    color: #3A65FF !important;
}

.slick-dots li button:before {
    color: #b5b5b5 !important;
    font-size: 16px !important;
}

.m-16 {
    margin-top: 16px;
}

.page-content {
    color: #999999;
}
.page-content_sub {
    color: #999999;
}
.padding-x-30{
    padding: 0 30%;
}

/* Logistics use case 2 section */

.usecase-problems{
    padding: 40px;
    background: #FFEEEE;
    border: 1px solid #FF0000;
}

.usecase-solutions{
    padding: 40px;
    background: #EFFFF0;
    border: 1px solid #17A600;
    border-left: unset;
}

.usecase-problems h2, .usecase-solutions h2{
    color: #161616;
    font-family: sora;
    font-size: min(max(20px, calc(1.25rem + (32 - 20) * ((100vw - 320px) / (1400 - 320)))), 32px);
    font-weight: 700;
    margin-bottom: 30px;
}

.usecase-problems p{
    color: #656565;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
}

.usecase-solutions li{
    color: #656565;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
    padding-bottom: 10px;
}
.usecase-solutions li b{
    color: #000;
}

@media screen and (max-width:1200px) {

    .expertise_section .card-style-1,
    .testing_section .card-style-1 {
        height: 60px !important;
    }

    .content-view{
        margin-left: 30px;
    }

}

@media screen and (max-width:700px) {
    .main-title {
        font-size: 14px;
    }

    .datascience_image {
        width: 80% !important;
    }


}

@media screen and (max-width: 1020px) {
    .padding-x-30{
        padding: 0 40px;
    }
}

@media screen and (min-width: 991px) {
    .pr-content {
        padding-right: 100px;
    }
}

@media screen and (max-width:992px) {
    .mobile-height{
        height: 400px !important;
    }
    .content-view{
        margin-left: unset;
    }

    .usecase-solutions{
        border-left: 1px solid #17A600;
        border-top: unset;
    }

    .usecase-problems h2, .usecase-solutions h2{
        margin-bottom: 20px;
    }

    .usecase-problems, .usecase-solutions{
        padding: 20px;
    }

    .pt-60 {
        padding-top: 10px !important;
    }
    .page-content{
        padding: 0px 55px !important;
    }
    .nav_bar_btn {
        width: 179px;
        height: 48px;
        font-size: 17px;
    }
}

@media screen and (max-width:767px) {

    .datacard_height_set .simple_card,
    .card_height_set .simple_card {
        height: auto !important;
    }
}

@media (max-width: 599px) {
    .mt-75 {
        margin-top: 35px !important;

    }

    .mb-75 {
        margin-bottom: 35px !important;
    }

    .mt-60 {
        margin-top: 10px !important;
    }
    .page-content{
        padding: 0px !important;
    }
}

@media (max-width: 1367px) {
    .sharepoint .simple_card {
        height: 210px !important;
    }
  
}



@media (max-width: 767px) {
    .sharepoint .simple_card {
        height: unset !important;
    }
    .mt-100{
        margin-top: 50px !important;
    }
    .mb-100{
        margin-bottom: 50px !important;
    }
    .mt_134{
        margin-top: 75px !important;
    }
    .mobile-height{
        height: 400px !important;
    }
}

.section_title {
    font-size: clamp(1.375rem, 1.1323rem + 1.2945vi, 2.375rem);
    font-family: sora;
    font-weight: 700;
    color: #131313;
    margin-bottom: 10px;
}

.section_content {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 600;
    color: #666666;
    width: 74%;
}


.solution_items {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    color: #666666;
}

.slick-track {
    margin-left: 0px;
}

/* .remove-slick-cloned .slick-cloned{
    display: none !important;
} */
.mt-130 {
    margin-top: 130px;
}

.ml-0 {
    margin-left: 0 !important;
}

.solution_item_card:hover .solution_icons path {
    fill: #3A65FF;
}

.solution_item_card:hover .solution_icons rect{
    fill: #3A65FF;
}

.solution_item_card:hover .solution_icons circle {
    stroke: #3A65FF;
}
.homepage .newNavebar .nav-link,.homepage .nav_items a {
    color: #fff;
}
.solution_item_card:hover .solution_icons .add-path {
    stroke: #3A65FF;
    fill: #fff;
}

.solution_card .slick-list {
    padding: 20px 0 !important;
}

.homepage .navbar_section {
    position: fixed !important;
}

/* .homepage .newNavebar .nav-link,
.homepage .nav_items a {
    color: #fff !important;
} */

.homepage .nav-item.dropdown .nav-link {
    color: #fff !important;
}

.homepage .newNavebar .nav-item.dropdown .nav-link {
    color: #000 !important;
}

@media screen and (max-width:991px) {

    .homepage .newNavebar .nav-link,
    .homepage .nav_items a {
        color: #000 !important;
    }

    .homepage .nav-item.dropdown .nav-link {
        color: #000 !important;
    }

    .mob_svg_icon svg {
        color: #000 !important;
    }

    .mobile_menu .dropdown-menu p a {
        color: #000;
    }
    .pharam div.about-banner .banner-title {
        line-height: normal !important;
    }
    .pharam div.about-banner {
        padding: 65px 0;
        padding-right: 30px;
    }
    .case_page .page-title{
        line-height: 50px;
        padding: 0px 8% !important;
     }
}

/* .remove-slick-cloned  .slick-slide{
    width: unset !important;
    margin-right: 35px !important;
} */

.work_page .navbar_section {
    background: white;
}
.case_study .mt-52 {
    margin-top: 32px;
}
.case_study .mt-50 {
    margin-top: 40px;
}



@media (min-width: 3072px){
    .section_title {
        font-size: 40px;
    }
    .m-logistic-content h1 {
        font-size: 38px;
    }
    .finance-content h1{
        font-size: 40px !important;
    }
    .finance-content ul li{
    line-height: 45px !important;
    }
    .finance-content h2{
        line-height: 55px !important;
        margin-right: 0px !important;
    }
    .finance-content p{
       line-height: 40px !important;
    }
    .finance-content{
        margin-right: 0px !important;
    }
}

.modal-backdrop.show{
    opacity: 0.1;
}
.pharam .about-banner .banner-title{
   line-height: 75px !important;
}
.banner_img_case{
    margin-top: 30px;
}
@media screen  and (max-width: 540px){

    .nav_bar_btn {
        width: 148px;
        height: 36px;
        font-size: 16px !important;
    }
    .case_page .page-title {
        line-height: 33px !important;
        padding: 0px 5% !important;
        
    }
    
}
input{
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;

}