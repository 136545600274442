.learnmore_bg {
    height: auto;
    margin: 70px 0;
    padding: 44px 86px 0 86px;
    background-color: #F0EDFF;
}

.flex_reverse {
    display: flex;
    flex-direction: row-reverse;
}
.learnmore_bg h1::after {
    content: '';
    position: absolute;
    background-color: #3A65FF;
    width: 60px;
    height: 8px;
    left: 0;
    top: -15px;
}
.learnmore_img_style {
    position: relative;
    bottom: 5pc;
}
#financial-recovery , #health-token-creation , #patient-communication , #vehicle-insurance, #eligibililty-module, #technology,
 #data-printing-transparency, #online-assessment, #virtual-classroom, #financial-recovery , #health-token-creation , #patient-communication , #vehicle-insurance, #eligibililty-module, #data-printing-transparency , #collection-module, #border-payment, #cryptocurrency-development , #private-blockchain, #control_centre, #digital_twin{
    padding-right: 6.5pc;
}
.learnmore_unordered {
    padding-right: 10pc;
    padding-left: 36px;
    border-left: 2px solid #3A65FF;
    border-width: 3px;

}
.learnmore_bg h1 {
    color: var(--primary-13, #131313);
    position: relative;
    margin-bottom: 50px;
font-family: sora;
  font-size: calc(16px + (40 - 14) * ((100vw - 300px) / (1600 - 300)));
font-style: normal;
font-weight: 700;
}
.learnmore_bg li {
    color: rgba(51, 51, 51, 0.75);
    margin-bottom: 12px;
font-family: Inter;
font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
font-style: normal;
font-weight: 400;
line-height: 23px; 
}
.healthcare_detail .banner_btn , .insurance_detail .banner_btn, .fintech_detail  .banner_btn,
 .supplychain_detail .banner_btn,
  .blockchain_detail .banner_btn,
 .education_detail .banner_btn, 
 .logistic_detail .banner_btn{
    display: none;
} 
@media screen and (min-width:1000px) {
    .block_chain_head h1 {
        padding-right: 1pc;
            }
    .supply_chain_head h1 {
        padding-right: 8pc !important;
    }
 
}
@media screen and (min-width:1100px) and (max-width:1500px) {
    .learnmore_bg h1 {
        font-size: 30px;
    }
}
@media screen and (max-width:1500px) {
    .learnmore_unordered {
        padding-right: 3pc;
    }
}
@media screen and (max-width:1100px) {
    .order_prop {
        display: flex;
        flex-direction: column;
    }
    .learnmore_img_style {
        position: unset;
        width: 340px;
        margin-bottom: 50px;
    }
    #financial-recovery, #health-token-creation, #patient-communication, #vehicle-insurance, #eligibililty-module, #data-printing-transparency, #online-assessment, #virtual-classroom, #financial-recovery, #health-token-creation, #patient-communication, #vehicle-insurance, #eligibililty-module, #data-printing-transparency, #collection-module, #border-payment, #cryptocurrency-development, #private-blockchain, #control_centre, #digital_twin {
        padding-right: 0;
    }
    .order_prop #order_2 {
        flex: 1;
        order: 4;
    }
    .order_prop #order_1 {
        order: 2;
    }
    .learnmore_bg {
        margin: 30px 0;
}
.learnmore_bg h1 {
    margin-bottom: 26px;
}
.learnmore_bg li {
    margin-bottom: 10px;
}
}
@media screen and (max-width:767px) {
    .learnmore_bg li {
        margin-bottom: 6px;
    }
    .learnmore_img_style {
        position: unset;
        width: 250px;
        margin-bottom: 50px;
    }
    .learnmore_bg {
        padding: 36px 36px 0 36px;
}
.learnmore_unordered {
    padding-right: 0pc;
    padding-left: 36px;
}
}


