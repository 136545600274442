.core-team-header h2 {
    font-family: sora, sans-serif;
    font-weight: 700;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    line-height: 40px;
}

.core-team-header p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    opacity: 85%;
    letter-spacing: -.25px;
    margin-top: 16px;
    margin-bottom: 0px;
}

.core-team-card {
    height: auto;
}

.core-team-card img{
    height: auto;
    object-fit: cover;
}


.core-team-card h2 {
    font-size: clamp(1.125rem, 0.9126rem + 1.1327vi, 28px);
    font-family: sora, sans-serif;
    font-weight: 500;
    color: #131313;
    line-height: 30px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.core-team-card h3 {
    font-size: 16px;
    font-family: 'Inter', 'Arial Narrow Bold', sans-serif;
    font-weight: 400;
    color: #4f6169;
    opacity: 75%;
    margin-bottom: 24px;
    line-height: 24px;
    letter-spacing: -.25px;
}



.leaders_row{
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1.5fr));
    column-gap: 4rem;
    justify-items: stretch;
    margin-top: 22px;
}

.core-team-card {
    
    padding: 2.7rem;

}

.core-team-card img{
    width: 13rem;
    min-width: 8rem;
}


@media screen and (max-width: 991px) {
    .core-team-header p {
        line-height: 28px;
        margin-top: 0px !important;
        text-align: justify;
    }
  
    
}


@media screen and (max-width:540px) {

    .mt-50.section_core{
        margin-top: 35px;
    }
    .leaders_row{
        display: grid;
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
    
    .core-team-card {
        padding: 36px 0px 0px 0px;
        text-align: center;

      }
}