.banner-section{
    /* height: 339px; */
    width: 100%;
    display: flex;
    align-items: center;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.page-title1{
    font-size: min(max(20px, calc(1.25rem + (32 - 20) * ((100vw - 20px) / (1400 - 320)))), 32px);
    color: white;
    font-weight: 600;
    line-height: 42px;
    font-family: sora,sans-serif;
    align-items: center;
    text-align: center;
}
.smalltext{
    color: #A5B9FF;
    font-size: min(max(15px, calc(0.9375rem + (24 - 15) * ((100vw - 320px) / (1400 - 320)))), 24px);
    font-family: 'Inter';
    font-weight: 700;
    margin-bottom: 0;
}
.p-0{
    padding: 0px !important;
}
@media screen and (max-width:800px) {
    .banner-section {
        background-size: cover !important;
        background-position:top center !important;
   }
    .banner_img_case{
        display: none;
   }
    .img_col_case{
        display: none;
   }
    .page-title1{
        line-height: 35px;
   }
}
@media screen and (min-width:800px) and (max-width: 1056px) {
    .banner_img_case{
        max-width: 85%;
   }
    /* .banner-section {
        height: 300px;
   } */
}
