@import 'bootstrap/dist/css/bootstrap.min.css';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import 'react-toastify/dist/ReactToastify.css';
@import "react-loading-skeleton/dist/skeleton.css";

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }

}

/* fonts  */

/* @font-face {
    font-family: gustavo_blod;
    src: url('./assets/fonts/Gustavo/Gustavo-Bold.otf');
  }

  @font-face {
    font-family: gustavo_italic_blod;
    src: url('./assets/fonts/Gustavo_Font/gustavo_bold_italic.ttf');
  }
  @font-face {
    font-family: gustavo_regular;
    src: url('./assets/fonts/Gustavo_Font/gustavo_regular.ttf');
  }
  @font-face {
    font-family: gustavo_italic;
    src: url('./assets/fonts/Gustavo_Font/gustavo_italic.ttf');
  }
  @font-face {
    font-family: gustavo_thin;
    src: url('./assets/fonts/Gustavo_Font/gustavo_thin.ttf');
  } */

.bg-section {
  padding: 50px 0;
  background-image: url('./assets/images/specrum-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

@font-face {
  font-family: sora;
  src: url('./assets/fonts/Gustavo/Gustavo-Bold.otf');
}

@font-face {
  font-family: gustavo-regular;
  src: url('./assets/fonts/Gustavo/Gustavo-Regular.otf');
}

@font-face {
  font-family: gustavo-medium;
  src: url('./assets/fonts/Gustavo/Gustavo-Medium.otf');
}
@font-face {
  font-family: sora;
  src: url('./assets/fonts/sora/Sora-VariableFont_wght.ttf');
}

/* Common button */
.btn_shadow {
  width: 292px;
  margin: auto;
  position: relative;
}

.shadow_1 {
  width: 292px;
  height: 63px;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
  position: absolute;
  top: 10px;
  left: 5px;
  background: #333;
}

.polygon_btn_cont {

  width: 292px;
  height: 63px;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
  position: relative;
  background: #333;
  box-sizing: border-box;
  margin: auto;
  z-index: 3;

}


.polygon_btn {
  width: 284px;
  height: 57px;
  top: 3px;
  left: 4px;
  position: absolute;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
  border: 1px solid #333 !important;
  background: #fff !important;

  color: #333 !important;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  border-radius: 0px 0px 0px 0px;


}

/* .polygon_btn:hover{
    background: #333 !important;
    color: #fff !important;
} */


.btn_shadow {
  /* transition: 0.34s all ease; */
  transition: top 0.4s ease-in-out;
}

.shadow_1 {
  transition: top 0.3s ease-in-out;
}

.btn_shadow:hover .shadow_1 {
  top: 0px;
  transition: top 0.3s ease-in-out;

}
.mt-100r{
  margin-top: 100px;
}
.mb-50{
margin-bottom: 50px ;
}

/****************************************** responsive ************************************************** */
@media screen and (max-width :540px) {
  .mt-100r{
  margin-top: 50px;
}
.mb-50{
  margin-bottom: 20px;
}
  .btn_shadow {
    width: 200px;
  }

  .hero_section_btn {
    margin-top: 20px;
  }

  .shadow_1 {
    width: 200px;
    height: 50px;
    top: 12px !important;
    left: 14px;

  }

  .polygon_btn_cont {
    width: 210px;
    height: 57px;
  }

  .polygon_btn {
    width: 200px;
    height: 50px;
    top: 3px;
    left: 4px;
    font-size: 16px;

  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.form-control,
.form-select {
  padding: 12.12px .75rem !important;
}

/* .work_page  .nav_items a{
  color: #000000;
}
.work_page .nav-item.dropdown .nav-link{
  color: #000000;
}
.work_page .nav-item.dropdown .nav-link:hover{
  color: #3A65FF !important;
}
.work_page .navbar_section{
  position: sticky !important;
  top: 0 !important;
}
.work_page .navbar_section_navbar{
  background-color: #ffffff !important;
}
.work_page .svg_icon svg{
color: #000 !important;
} */
.workcard:nth-child(5) .workcard_style .img_end {
  text-align: end;
}

.workcard:nth-child(5) .workcard_style .center_img {
  align-self: center !important;
}

/* .workcard{
  margin-top: 60px;
}
.workcard:nth-child(1) {
  margin-top: 60px !important;
} */
.workcardadd {
  margin-top: 0 !important;
}

.workcard_style {
  height: 496px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.workcard_study {
  font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;

}

.workcard_title {
  font-size: min(max(19px, calc(1.1875rem + (40 - 19) * ((100vw - 320px) / (1400 - 320)))), 40px);
  font-family: sora;
  color: white;
  margin-bottom: 10px;
}

.workcard_content {
  font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #ffffffbd;
  margin-bottom: 22px;
  width: 78%;
}

.mb-30 {
  margin-bottom: 30px;
}

.case_study_btn {

  border-radius: 6px !important;
  color: #fff !important;
  padding: 6px 24px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #3A65FF !important;
  outline: none;
  border: none;
  height: 41px;
}

.homepage .nav_items a {
  color: #fff;
}

.homepage .nav-item.dropdown .nav-link {
  color: #fff;
}

.homepage .svg_icon svg {
  color: #fff;
}

.logo {
  fill: #000 !important;
}

/* workcar_responsive */

@media (max-width: 992px) {
  .workcard_style .image_col {
    display: none !important;
  }

  .img_col_case {
    display: none !important;
  }

  .case_study_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0 !important;
  }
  .overview_content p {
    width: 100%;
  }
}

@media screen and (max-width:800px) {
  .workcard_style {
    height: 100%;
    width: 100%;
  }

  .work_card_cont {
    padding: 20px !important;
  }

  .workcard_content {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .case_study_btn {

    padding: 0px 10px;

    font-size: 13px;
    font-weight: 500;

    height: 32px;
  }
}

@media screen and (min-width:540px) and (max-width: 800px) {
  .workcard_content {
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }


}

@media screen and (min-width:800px) and (max-width: 1056px) {

  .workcard_style {
    height: 100%;
    width: 100%;
  }

  .work_card_cont {
    padding: 28px !important;
  }

  .workcard_style .image_col img {
    max-width: 87%;
  }

  .workcard_content {

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .workcard:nth-child(2) .workcard_style .img_end {
    text-align: end;
  }
}

.case_study_heading p {
  font-family: 'Inter', sans-serif;
  font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 600;
  color: #000000;
  opacity: 100%;
  margin-bottom: 26px;
}

.case_study_heading p a {
  color: #000000;
  opacity: 100%;
  text-decoration: none;
}


.overview_content p {
  font-size: clamp(1.25rem, 1.1842rem + 0.3509vi, 1.5rem);
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #000000;
  opacity: 75%;
  line-height: 38px;
  margin-bottom: 0px;
  width: 85%;
}

.overview_content p span{
  font-weight: 700;
}

.challenges_content h2 {
  font-size: min(max(18px, calc(1.125rem + (38 - 18) * ((100vw - 320px) / (1400 - 320)))), 38px);
  font-family: sora;
  color: #000;
  margin-bottom: 24px;
}

.soultion_content h2 {
  font-size: min(max(18px, calc(1.125rem + (38 - 18) * ((100vw - 320px) / (1400 - 320)))), 38px);
  font-family: sora;
  color: #000;
  margin-bottom: 24px;
  width: 82%;
}

.challenges_content h2 {
  margin-bottom: 24px;
}

.case_study_heading {
  position: sticky;
  top: 130px;
}

.soultion_content p {
  font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #000;
  opacity: 75%;
  margin-bottom: 24px;
  line-height: 32px;
  width: 80%;
}

.soultion_content h3,
.challenges_content h3 {
  font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #333333;

}

.mt-52 {
  margin-top: 52px;
}

.mb-70 {
  margin-bottom: 70px;
}

.soultion_content h6,
.challenges_content h6 {
  font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #666666;
  line-height: 32px;

}

.mt-36 {
  margin-top: 36px;
}

.mt-50 {
  margin-top: 36px;
}

.challenges_content p {
  font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #000;
  opacity: 75%;
  margin-bottom: 24px;
  line-height: 32px;
  width: 80%;
}

.challenges_content ul li {
  font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #000;
  opacity: 75%;
  margin-bottom: 20px;
  line-height: 32px;
}

.case_study_img {
  height: 367px;
  width: 100%;
}
.mb-24{
  margin-bottom: 24px;
}
/* offset  */
.new-model .modal-content{
  border-radius: 20px !important;
}

.success_popup_form{
  padding: 42px;
  height: 540px;
  position: relative;
  padding-bottom: 55px;

}
.close_btn_success{
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 23px;
  cursor: pointer;
  color: #656565;
}
.success_popup_content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.success_popup_content.job_apply{
  justify-content: space-between;
}
.success_popup_content img{

  height: 200px;
  width: 200px;
  
}
.job_btn_pop{
  all: unset;
  height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3A65FF;
  color: #FFFFFF;
  font-family: Inter;
  font-weight: 700;
  font-size: 16px;

}
.success_popup_content h1{
  font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
  margin: 0px auto;
  font-family:  sora;
  color: #121212;
  font-weight: 700;
  margin-top: 18px;
}
.success_popup_content p{
  font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
  margin: 0px auto;
  font-family:  Inter;
  color: #656565;
  font-weight: 600;
  margin-top: 16px;

}
.success_popup_content p span{
  font-weight: 700;
  color: #2D2D2D;
}
.banner_btn.careers{
  background-color: transparent !important;
  border: 2px solid #fff;
  padding: 6px 36px;
}
.banner_btn.careers:hover{
  background-color: #fff !important;
  color: #000000 !important;
}
/* responsive for case study  */
@media screen and (max-width: 992px){

  .overview_content p {
    width: 100% !important;
    line-height: 32px !important;
    font-size: 18px ;
    text-align: justify;
  }
 

}

@media screen and (max-width:810px) {
  .soultion_content h2 {
    width: 100%;
  }

  .case_study_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .overview_content,
  .challenges_content,
  .soultion_content {
    padding: 0px 12px;
    width: 100%;
  }

  .overview_content p,
  .soultion_content h6 {
    line-height: 30px;
  }

  .challenges_content p,
  .soultion_content p,
  .challenges_content ul li {
    width: 100%;
    line-height: 30px;
  }

  .case_study_img {
    height: 170px;
  }

  .case_study .mt-50 {
    margin-top: 25px;
  }

  .case_study .mt-52 {
    margin-top: 26px;
  }

}

.service-page .learnmore_bg{
    background: #F6F6F6;
}

@media screen and (max-width: 540px) {
  .service-section .service-card-title {
     margin-top: 20px;
  }
  
  .success_popup_content img{

    height: 115px;
    width: 115px;
    
  }
  .success_popup_form {
    height: 350px;
  }

}

@media screen and (max-width:991px){

  .success_popup_content img{

    height: 160px;
    width: 160px;
    
  }
  .success_popup_form {
    height: 450px;
  }
}

.infinite-scroll-component{
  overflow: unset !important;
}