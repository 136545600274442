
.our_missons{
    /* position: absolute;
    top: 40%;
    left: 32%;
    transform: translate(-32%, -40%);  */
}
.our_missons h2{
    font-size: clamp(1.75rem, 1.568rem + 0.9709vi, 2.5rem);
    font-family: sora;
    font-weight: 700;
    color: #121212;
    margin-bottom: 20px;
}
.our_missons p{
    font-size: clamp(0.9375rem, 0.892rem + 0.2427vi, 1.125rem);
    font-family: Inter,sans-serif;
    font-weight: 400;
    color: #656565;
    line-height: 27px;
}

@media screen and (max-width:800px) {
    .our_missons{
      position: static !important;
       padding: 20px !important;
       padding-top: 0px !important;
       transform: none !important;
    }

    
}
@media screen and (max-width:992px) {
    
    .our-misson-row{
        padding: 0 !important;
    }

    div.mt-110{
        margin-top: 55px !important;
    }
    .col_padd{
        padding: 0px 16px !important;
        margin-bottom: 0px !important;
    }
    .Our-Missions-img-col{
        display: none !important;
       
    } 
}

@media screen and (max-width:540px) {
    .our_miissons{
       padding: 0px !important;
       padding-bottom: 50px !important;
      
    }
    .our-misson-row{
        padding: 0 !important;
    }
    .our_missons p{
        text-align: left;
    }

    
}
@media screen and (min-width: 991px) and (max-width: 1055px){
    .our_missons{
        position: static !important;
        padding: 36px !important;
        padding-top: 0px !important;
        transform: none !important;
    }
    .col_padd {
        padding: 0px 16px !important;
        margin-bottom: 0px !important;
    }
    .our-misson-row{
        padding: 0px !important;
    }

}
@media screen and (min-width: 1056px) and (max-width: 1380px){
    .our_missons{
        padding-left: 80px;
    }
    
}


/* for servicess  */


.sevices_layout .our_missons h2 {
    display: none !important;
}

.sevices_layout .our_missons p {
  color: #161616;
}
.mt-110{
    margin-top: 100px !important;
}