.details h3,
.details span,
.career_title label,
.details button,
.details a {
    font-family: Inter !important;
}

.details h2 {
    margin-right: 16px;
}

.main-title {
    font-size: calc(14px + (28 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-family: sora;
}

.details span {
    font-size: 16px;
    margin-left: 8px;
    color: #363636;
    font-weight: 500;
}

.color_blue {
    color: #019ce8 !important;
    font-size: 14px;
    font-weight: bold;
}

.details svg {
    font-size: 24px;
}

.details h3 {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
}

.emp_details {
    font-weight: bold !important;
    color: #202020 !important;
}

.responsible_section h3 {
    font-weight: normal !important;
}

.apply_now {
    border: 1.4px solid #3A65FF;
    background: #3A65FF;
    color: white;
    font-weight: 600;
    padding: 5px 50px 5px 50px;
    margin: 30px 0;
    border-radius: 2px;
}

.apply_now:hover {
    background: #0033eb !important;
    color: white !important;
}

.color-black {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;
}

.color-black:hover {
    color: #019ce8;
}

.color-black:hover span {
    color: #019ce8;
}

.emp_responsibilities {
    font-weight: bold !important;
}

.wrap {
    flex-wrap: wrap;
}

.view_link_previous {
    text-decoration: none !important;
}

/* Vijay css */
.job_detail_main{
    margin-top: 50px;
}

.job-view-section .post_date{
    font-size: 14px;
   font-family: Inter !important;
    line-height: 28px;
    font-weight: 700;
    color: #717171;
}
.job_overview{
    margin-top: 35px;
}
.job_overview p{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
   font-family: Inter !important;
    line-height: 28px;
    font-weight: 500;
    color: #717171;
    margin-top: 0px;
    margin-bottom: 10px;
}
.job_overview p:last-child{
    margin-bottom: 0px;
}
.job_overview p span{
    
    font-weight: 700;
    color: #131313;
}
.job_view_description{
    margin-top: 35px;
}

.job_view_description h5{
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    line-height: 29px;
    font-weight: 700;
    font-family: var(--heebo);
    color: #131313;
    opacity: 85%;
    margin-bottom: 10px;

}
.job_view_description p{
    /* font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem); */
   font-family: Inter !important;
    line-height: 28px;
    /* font-weight: 500; */
    color: #666666;
    /* margin-top: 20px; */
    /* margin-bottom: 0px; */
}
.job_view_description ol {
    margin-top: 20px;
}
.job_view_description ol li{
    /* font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem); */
   font-family: Inter !important;
    line-height: 28px;
    /* font-weight: 500; */
    color: #666666;
    /* margin-top: 6px; */
    /* margin-bottom: 0px; */
}
.job_view_description ul li{
    /* font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem); */
   font-family: Inter !important;
    line-height: 28px;
    /* font-weight: 500; */
    color: #666666;
    /* margin-top: 6px; */
    /* margin-bottom: 0px; */
}

.job-view-btn{
    all: unset;
    width: 221px;
    height: 40px;
    border-radius: 5px;
    background: var(--primary);
    color: #fff;
    font-family: var(--Open-sans);
    font-weight: 700;
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    text-align: center;
    margin-top: 40px;
}
.job-view-btn:hover{
    background-color: var(--primary-hover);
}
.job-info-section{
    margin-top: 40px;
}
.job-info-overview{
    margin-top: 26px;
}
.job-info-section-container{
  margin-bottom: 20px;
}
.job-info-section-container span{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
   font-family: Inter !important;
    line-height: 32px;
    font-weight: 400;
    color: #000000;
    opacity: 75%;
}
.job-info-section-container h5{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
   font-family: Inter !important;
    line-height: 32px;
    font-weight: 700;
    color: #000000;
    opacity: 75%;
    margin: 0px;
    margin-top: -4px;
}
.job_detail_main{
    margin-top: 50px;
}
.job_keys{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 30px; */
}
.job_keys_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-right: 20px;
    margin-bottom: 14px;
}

.job_keys_cont p{
    margin: 0px;
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    line-height: 28px;
    font-weight: 600;
    font-family: Inter !important;
    color: #131313;
    opacity: 75%;
}
.chip-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
}
.chip {
    background: #ffffff !important;
    border: 1px solid #ccf3d6;
    padding: 2px 20px 4px 20px !important;
    border-radius: 30px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    line-height: 28px !important;
}