.service_key_section{
    margin-top: 75px;
    margin-bottom: 50px;
}

.services-key-title{
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-weight: 600;
    font-family: sora;
    color: #121212;
    margin: 0px;
    margin-bottom: 20px;

}

.service_key_service_card{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 2px solid #EFEFEF;
    box-shadow: 6px 8px 9px 0px #E0E0E01F;
    padding: 24px;
   

}
.service_key_service_card_content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    gap: 20px;
}
.service_key_service_card_content_main{
    flex-basis: 70%;
}
.services_key_service_image{
    /* height: 239px; */
    width: 100%;
    flex-basis: 30%;
}
.services_key_service_image img{
  
    width: 100%;
    height: 100%;
   
}
.service_key_service_card_content_main h3{
    font-size: clamp(1.125rem, 0.9605rem + 0.8772vi, 1.75rem);
    font-weight: 600;
    font-family: sora;
    color: #121212;
    margin: 0px;
    position: relative;

}
.service_key_service_card_content_main h3::before{
content: '';
position: absolute;    
left: -27px;
width: 6px;
height: 100%;
background-color: #3A65FF;
}

.service_key_service_card_content_main p{
    font-size: clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color:  #666666;
    margin: 0px;
    line-height: 24px;
    margin-top: 24px;

}
.mt24{
    margin-top: 26px;
}

@media screen and (max-width:992px) {

    .service_key_section{
        margin-top: 35px;
        margin-bottom: 40px;
    }
    
}