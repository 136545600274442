.contactnew_form input,
.contactnew_form select,
.contactnew_form textarea {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: unset !important;
}

.contect-page {
    background-image: url('../../assets/images/contect-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.form-check-input{
    box-shadow: none !important;
}
.pt-60 {
    padding-top: 60px;
}

.contactform_title {
    font-weight: bold !important;
    margin-right: 20%;
    color: #131313;
}

.contactnew_form label {
    font-size: 14px;
    color: #838383 !important;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.contactnew_form input:hover,
.contactnew_form select:hover,
.contactnew_form textarea:hover{
   border-bottom: #838383 solid 1px;
}

.form-control,
.form-select {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
}

.contect-page .form-control,
.form-select {
    padding-top: 1.625rem !important;
    padding-bottom: 0.625rem !important;
}

.pl-14 {
    padding-left: 14px !important;
}

.mb_4 {
    margin-bottom: 10px;
}

.link_section {
    padding-right: 12pc;
}

.contactform_head span {
    color: rgba(0, 0, 0, 0.75);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.underline_link {
    color: rgba(0, 0, 0, 0.75);
    padding-bottom: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.75);
    text-decoration: none;
    cursor: pointer;
}

.clr_blue {
    color: #3A65FF !important;
    border-bottom: 1px solid #3A65FF !important;
}

.check_box input {
    width: 22px !important;
    height: 22px !important;
}
.checkbox:hover{
    cursor: pointer !important;
}

.ml_1 {
    margin-left: 12px;
}

.contactform_btn {
    background: var(--p1, #3A65FF);
    width: 179px;
    height: 48px;
    padding: 10px;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    border-radius: 6px !important;
    margin-top: 32px !important;
}

.contactform_head {
    border-radius: 10px;
    padding: 20px 32px 35px 32px;
    /* position: absolute; */
    border: 1px solid #EDEDED;
    background: #FFF;
    box-shadow: 4px 4px 24px 0px rgba(203, 203, 203, 0.25);
    margin-bottom: 80px;
    margin-right: 60px;
    margin-left: 0px;
}

@media screen and (max-width:1345px) {
    .contect-page {
        background-position: right;
    }
}

@media screen and (min-width:1000px) and (max-width:1300px) {
    .contactform_head {
        width: 80% !important;
    }
}

@media screen and (max-width:992px) {
    .contect-page {
        background-image: unset;
    }

    .contactform_head {
        margin-right: 0;
        margin-left: 0;
    }

    .link_section {
        padding-right: unset !important;
    }
}

@media screen and (max-width:1500px) {
    .contactform_head {
        position: unset !important;
        margin-bottom: 40px;
    }
}

@media screen and (max-width:1421px) {
    .link_section {
        padding-right: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .sm-pb-3 {
        padding-bottom: 20px;
    }
}