.stepperdir{
    flex:0 2 1;
    width: 20%;
}
.historyheading{
    margin-left: 16px !important;
    font-family: sora, sans-serif !important;
    font-weight: 400 !important;
    font-size: 32px !important;
}
.historylabel{
    font-size: 40px !important;
    font-weight: 400 !important;
    font-family: 'sora', Helvetica, sans-serif !important;
    line-height: 38px !important;
    background: none !important;
    width: fit-content !important;
}
.history-card-main{
    font-family: sora, sans-serif !important;
    font-weight: 700;
    font-size: 40px;
    color: #000000;
    margin-top: 30px;
    line-height: 40px;
}
.stepnone text{
    display: none;
}
.about-main-section{
    background-color:  #FAFAFA !important;
}
.MuiStep-root {
    color: black !important;
  }
.MuiStepIcon-root:not(.Mui-active) {
    color: rgb(0, 0, 0) !important;
    padding: 6px !important;
  }

.MuiStepConnector-line {
    border-color: black !important;
  }
  
.MuiStepLabel-label:not(.Mui-active){
    font-family: sora, sans-serif !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #000000 !important;

}
.MuiStepConnector-line {
    display: block !important;
    border-color: #000000 !important;
    border-left-style: solid !important;
    border-left-width: 2px !important;
    min-height: 50px !important;
    margin-left: -1px;
    margin-top: -18px !important;
    margin-bottom: -18px !important;
}
.MuiStepLabel-root {
    padding: -100px 0 !important;
}
.MuiStepIcon-root:not(.Mui-active) {
    background-color: rgb(0, 0, 0) !important;
    width: 2px !important;
    height: 2px !important;
    border-radius: 50% !important;
    padding: -10px !important;
    margin-left: 6px !important;
}


 @media only screen and (min-width: 1000px) {
    .our_history{
        margin-left: 80px !important;
    }
  }

  @media only screen and (max-width: 400px) {
    .step-col{
        width: 100%;
        justify-content: center;
    }
  }
  