.nimaalan-not-found {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .nimaalan-not-found img {
    width: min(80%, 500px);
    margin-bottom: 5px;
  }
  
  .back-to-home {
    margin-top: 5px;
  }
  
  .btn-back-home{
    color: #3A65FF;
    cursor: pointer;
    font-size: large;
    font-weight: 600;
  }
  
  
  