.services_bg {
    height: auto;
    margin-top: 120px;
    padding: 44px 86px 0 86px;
    background-color: #F6F6F6;
}
.services_bg:last-child{
    margin-bottom: 100px;
}
.flex_reverse {
    display: flex;
    flex-direction: row-reverse;
}

.services_bg h1::after {
    content: none;
    position: absolute;
    background-color: #3A65FF;
    width: 60px;
    height: 8px;
    left: 0;
    top: -15px;
}

.service_img_style {
    position: relative;
    bottom: 5pc;
}

#technology {
    padding-right: 8.5pc;
}

#outsourcing,
#consulting {
    padding-left: 12.5pc;
}



.services_unordered {
    padding-left: 0px !important;
    /* border-left: 2px solid #3A65FF; */
    border-width: 3px;

}

.services_bg h1 {
    color: var(--primary-13, #131313);
    position: relative;
    margin-bottom: 20px;
    font-family: sora;
    font-size: calc(16px + (40 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-style: normal;
    font-weight: 700;
}

.services_bg p {
    color: rgba(51, 51, 51, 0.75);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.16px;
    margin-bottom: 30px;
}

.arrow-icon {
    font-size: 24px;
    color: #D2D2D2;
}


.services_bg li {
    color: rgba(51, 51, 51, 0.75);
    margin-bottom: 12px;
    font-family: Inter;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    list-style-type: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    color: #939393;
    box-shadow: -4px -4px 16px 5px #EDEDED40;
    

}

/* .services_bg li:nth-child(1) {
    color: rgba(51, 51, 51, 0.95);
    font-weight: 700;

}

.services_bg li:nth-child(1) .arrow-icon {
    font-size: 24px;
    color: #3A65FF;
} */

.services_bg li:hover {
    background: #fff;
    color: rgba(51, 51, 51, 0.95);
    font-weight: 700;
    cursor: pointer;
}

.services_bg li:hover .arrow-icon {
    display: block;
    color: #3A65FF;
}


@media screen and (min-width:1100px) and (max-width:1500px) {
    .services_bg h1 {
        font-size: 30px;
    }
}

@media screen and (max-width:1500px) {
    .services_unordered {
        padding-right: 3pc;
    }
}

@media screen and (max-width:1100px) {
    .order_prop {
        display: flex;
        flex-direction: column;
    }

    .service_img_style {
        position: unset;
        width: 340px;
        margin-bottom: 50px;
    }

    #technology {
        padding-right: 0;
    }

    #outsourcing,
    #consulting {
        padding-left: 0;
    }

    .order_prop #order_2 {
        flex: 1;
        order: 4;
    }

    .order_prop #order_1 {
        order: 2;
    }

    .services_bg {
        margin: 30px 0;
    }

    .services_bg h1 {
        margin-bottom: 26px;
    }

    .services_bg li {
        margin-bottom: 10px;
    }
}

@media screen and (max-width:767px) {
    .services_bg li {
        margin-bottom: 6px;
    }

    .service_img_style {
        position: unset;
        width: 250px;
        margin-bottom: 50px;
    }

    .services_bg {
        padding: 36px 36px 0 36px;
    }

    .services_unordered {
        padding-right: 0pc;
        padding-left: 20px;
    }
}

@media screen and (max-width:500px) {
    .services_bg li:hover .arrow-icon {
        display: none;
    }
    .services_bg li{
        margin-bottom: 0;
        padding: 6px 10px;
    }
}