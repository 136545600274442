.our-mission-card{
    background-color: #3A65FF ;
    color:#FFFFFF;
    margin-bottom: 0; /* Remove margin bottom */
    
}
.our-story-card{
    background-color: #131313;
    color:#FFFFFF;
    margin-bottom: 0; /* Remove margin bottom */
}



.our-mission-card, .our-story-card, .missionimgcard{
    border-radius: 5px !important;
    border: none !important;
    height: 482px;
    width: 100%;
    border-radius: 0px !important;
    flex-basis: 33.3%;
    padding: 55px 70px;
}

.our-mission-content{
    display: flex;
    height: 100%;
    flex-direction: column;
}



.our-mission-content h1{
    font-family: sora, sans-serif;
    font-weight: 700;
    font-size: clamp(1.375rem, 1.0789rem + 1.5789vi, 2.5rem);
    margin-bottom: 28px;
}
.our-mission-content .sec-tit{
    font-family: 'Inter';
    font-weight: 400;
    font-style: italic;
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    line-height: 32px;
    opacity: 85%;
}
.our-mission-content .content{
    font-family: 'Inter';
    font-weight: 400;
    font-size:  clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    line-height: 32px;
}

.mission_vission{
    display: flex;
    align-items: center;
    justify-content: center;
}
.image_flex{
    flex-basis: 33.3%;
    height: 482px;
}
.image_flex img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}




@media screen and (max-width:991px) {

    .mission_vission{
        display: block;
    }

    .our-mission-card, .our-story-card, .missionimgcard{
        height: 100%;
        padding: 26px !important;
    }
    .image_flex{
        display: none;
    }
    .our-mission-content .content{
        text-align: justify;

}

    
}

@media screen and (max-width:1056px) {
    .mission_vission{
        display: block;
    }

    .our-mission-card, .our-story-card, .missionimgcard{
        height: 100%;
        padding: 50px;
    } 
    .image_flex{
        display: none;
    }
}


@media screen and (min-width:1100px) and (max-width:1380px){
    .our-mission-card, .our-story-card{
        height: 575px;
        padding: 40px 84px;
    }
    .image_flex{
        height: 575px !important;
       
    }
    .image_flex img{
        object-fit: cover;
    }
    

}    
